.main{
    background-color: rgb(20, 30, 37);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    color: white;
}
.page{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgb(20, 30, 37);
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgb(30, 44, 56) rgb(20, 30, 37);
}
.collectcontainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 1500px;
    justify-content: space-around;
    margin-top: 100px;
}
iframe{
    margin: 50px;
}
.c_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 900px;
    height: 1000px;
    text-align: center;
    font-family: Karla;
    margin-bottom: 40px;
}
.c_info:hover{
    background-color: #183b5946;
    border-radius: 70px;
    padding: 10px;
}
.c_info h1{
    font-size: 40px;
}
.c_info p{
    font-size: 20px;
}
.c_button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 300px;
    border: 5px solid #2c5980;
    border-radius: 40px;
    padding: 20px;
    margin: 40px;
}
.c_button h3{
    font-family: Karla;
    font-size: 30px;
}

/* ===WARNING=== */
.warning{
    width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 25px;
    font-family: Karla; 
}
.warn{
    font-size: 50px;
    color: rgb(255, 177, 74);
    animation: move 2s infinite;
}
.warn:hover{
    animation: shake 0.5s 2;
}
@keyframes move {
    0%{
        transform: translate(0px, 0px);
    }
    50%{
        transform: translate(0px, 5px);
    }
    100%{
        transform: translate(0px, 0px);
    }
}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.buttons{
    font-family: Karla;
    width: 70px;
    height: 30px;
    background-color: rgb(85, 128, 159);
    border: 3px solid rgb(249, 249, 249);
    border-radius: 20px;
}
.buttons:hover{
    background-color: rgba(255, 98, 98, 0.692);
    box-shadow: 0px 5px;
    translate: 0px -5px;
}
.buttons:active{
    background-color: rgba(255, 174, 174, 0.692);
    translate: 0px -4px;
}