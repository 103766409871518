.navbox{
    color: rgb(255, 255, 255);
    display: grid;
    justify-items: center;
    align-items: center;

    width: 300px;
    height: 100vh;
    background-color: rgb(30, 44, 56);
    

    font-family: Karla;
}
.navfooter{
    justify-self: end;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    
    text-align: center;
}
.title{
    width: 100px;
    color: rgb(255, 90, 90);
    text-shadow: 1px 1px 2px rgb(253, 253, 253);
    font-family: Karla;
    font-size: 30px;
}
.navbox button{
    background: none;
    border: none;
    border-radius: 40px;
    width: 200px;
    padding: 10px;
    
}
.navbox button:hover{
    background-color: rgb(20, 30, 37);
    font-size: 20px;
}
.navbox button h1{
    color: white !important;
}
.navfooter button{
    color: white;
    font-family: Karla;
    font-size: 20px;
}