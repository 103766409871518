.box{
    position:relative;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 800px;
    border-radius: 75px;
    border: 5px solid rgb(255, 220, 220);
    color: rgb(255, 255, 255);
    box-shadow: 10px 10px 3px rgb(20, 30, 37);
    background-color: rgb(30, 44, 56);
}
.text{
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    font-family: Karla;
    font-size: large;
}
.title{
    height: 100px;
    display: flex;
    align-self: flex-start;
    justify-self: center;
    color: rgb(255, 90, 90);
    text-shadow: 1px 1px 2px rgb(253, 253, 253);
    font-family: Karla;
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.info{
    display: flex;
    flex-direction: column;
    
    font-family: Karla;
    margin: 0 auto;
    text-align: center;
    border: 3px solid rgb(255, 220, 220);
    border-radius: 15px;
    margin: 10px;
}
.info p{
    margin: 10px;
}
.arrow{
    display: flex;
    justify-content: center;
    padding-top: 10px;
    
}
.arrow button{
    background: none;
    border: none;
    font-size: 50px;
    color: red;
    border-top: 1px solid red;
}