.lbox input{
    padding: 5px;
    width: 300px;
    background-color: black;
    border: 1px solid white;
    border-radius: 50px;
    color: white;
}
.icontainter{
    max-width: 500px;
    width: 400px;
    display: flex;

}
.inp {
    text-align: center;
    color: rgb(255, 255, 255);
    width: 400px;
    height: 30px;
    font-family: Karla;
    font-size: 20px;
}
.inp::placeholder {
    color: rgba(158, 125, 196, 0.556);
}
.space{
    height: 20px
}
.lbox{
    display: flex;
    flex-direction: column;
}
.lbox p{
    font-size: 20px;
    font-family: Karla;
    color: white;
    text-align: center;
}
.lbox form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.BUTT{
    margin: 0 auto;
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    width: 200px;
    height: 3rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.BUTT:hover{
    box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
    translate: 0px -5px;
    transition-duration: .1s;
}
.spinner{
    width: 100%;
    height: 100vh;
    background-color: #673FD7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    transform: rotateZ(45deg);
    perspective: 1000px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #fff;
  }
  .loader:before,
  .loader:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      border-radius: 50%;
      transform: rotateX(70deg);
      animation: 1s spin linear infinite;
}
  .loader:after {
      color: #FF3D00;
      transform: rotateY(70deg);
      animation-delay: .4s;
}

  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotateZ(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotateZ(360deg);
    }
  }
  @keyframes rotateccw {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
  @keyframes spin {
    0%,
    100% {
      box-shadow: .2em 0px 0 0px currentcolor;
    }
    12% {
      box-shadow: .2em .2em 0 0 currentcolor;
    }
    25% {
      box-shadow: 0 .2em 0 0px currentcolor;
    }
    37% {
      box-shadow: -.2em .2em 0 0 currentcolor;
    }
    50% {
      box-shadow: -.2em 0 0 0 currentcolor;
    }
    62% {
      box-shadow: -.2em -.2em 0 0 currentcolor;
    }
    75% {
      box-shadow: 0px -.2em 0 0 currentcolor;
    }
    87% {
      box-shadow: .2em -.2em 0 0 currentcolor;
    }
  }

