.main{
    font-family: Karla;
    font-size: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1;
    justify-content: space-around;
    
}
.main:hover{
    background-color: rgb(30, 44, 56);
}
.head{
    grid-column: 1;
    justify-self: center;
    align-self: center;
    width: 400px;
}
.value{
    grid-column: 2;
    justify-self: center;
    align-self: center;
}