.main{
    width: 100%;
    height: 100vh;
    background-color: rgb(20, 30, 37);

    display: flex;
    flex-direction: row;
    color: white;
}

.page{
    width: 100%;
    gap: 100px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(7, 1fr);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgb(30, 44, 56) rgb(20, 30, 37);
}
.datainfo{
    grid-row: 1;
    grid-column: 1/5;
    background-color: rgb(30, 44, 56);
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 400px;
    font-family: Karla;
    font-size: 20px;
}
.textcon{
    width: 700px;
    margin-left: 270px;
    padding-left: 30px;
    border-left: 10px solid rgb(67, 195, 255);
}
/* .update{
    border: 3px solid #3c5163;
    background-color: #1e2c38;
    border-radius: 40px;
    width: 300px;
    height: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Karla;
}
.update:hover{
    background-color: #3c5163;
    border: 3px solid #1e2c38;
} */
.buttons{
    font-family: Karla;
    width: 70px;
    height: 30px;
    background-color: rgb(85, 128, 159);
    border: 3px solid rgb(249, 249, 249);
    border-radius: 20px;
}
.calendar{
    padding-left: 30px; padding-right:  30px;
    grid-column: 2 / 4;
    grid-row: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 20px;
    width: 700px;
    height: 900px;
}
.calinfo{
    display: flex;
    flex-direction: column;
    font-family: Karla;
    font-size: 1.5rem;
    text-align: center;
    width: 900px;
    height: 400px;
}
.iCal{
    border: 3px solid #3c5163;
    background: #1e2c38;
    border-radius: 40px;
    padding-left: 30px; padding-right:  30px;
    grid-column: 2 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
    height: 200px;
}
.iCal:hover{
    border: 3px solid #2c5980;
    
}
.calendar h3{
    text-align: center;
    width: 100px;
    background-color: #3c5163;
    font-family: Karla;
    font-size: 20px;
    padding: 0;
    margin: 0;
}
.thearc{
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    grid-row: 2;
    grid-column: 1 / 5;
    background-color: rgb(25, 35, 43);
    border-left: 10px solid rgb(30, 44, 56);
}
.thearc:hover{
    background-color: rgb(30, 44, 56);
    border-left: 10px solid rgb(67, 195, 255);
}
.pie{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 546px;
}

.infoboard{
    width: 100%;
    height: 900px;
    grid-row: 4 / 6;
    grid-column: 1/5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

/* ===INFO BOARDS?=== */
.arcinfo{
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 300px;
    font-family: Karla;
    font-size: 30px;
    padding: 30px;
}

.arcinfo h1{
    text-decoration: underline;
    font-style: italic;
}

.updatecontainer{
    position: fixed;
    grid-row: 1;
    width: 150px;
    background-color: rgba(36, 60, 74, 0.377);
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 5px solid rgba(67, 195, 255, 0.421);
}
.update{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    text-align: center;
    font-family: Karla;
    font-size: 11px;
}
.updatecontainer:hover{
    background-color: rgba(36, 60, 74, 0.692);
    border: 5px solid rgba(255, 89, 89, 0.692);
}
.buttons:hover{
    background-color: rgba(255, 98, 98, 0.692);
    box-shadow: 0px 5px;
    translate: 0px -5px;
}
.buttons:active{
    background-color: rgba(255, 174, 174, 0.692);
    translate: 0px -4px;
}


.ass{
    grid-row: 7;
    grid-column: 1/5;
    width: 1000px;
    height: 70vh;
    justify-self: center;
    overflow-y: hidden;
    margin-bottom: 50px;
    background-color: rgb(20, 30, 37);
    border: 5px solid rgb(51, 85, 114);
    border-radius: 20px;
    padding: 50px;
}
.assinfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-family: Karla;
    border-bottom: 5px solid rgb(51, 85, 114);
    margin: 10px;
    margin-top: 0px;
    padding: 10px;
}
.assinfo h1{
    font-size: 40px;
}
.assinfo p{
    font-size: 20px;
}
.asscontainer{
    height: 50vh;
    overflow-y: scroll;
}
.aRow{
    margin: 10px;
}


