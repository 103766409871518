.main{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    background-image: url('/src/media/databg.jpg');
    background-color: black;
    background-size: cover;
    background-repeat: no-repeat; 
}
.line{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3px;
    height: 85vh;
    background-color: white;
    border-radius: 20%;
}
.lineholder{
    background-color: transparent;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
}

.loginbox{
    border: 3px solid grey;
    border-radius: 70px;

    height: 750px;
    background-color: rgba(0, 0, 0, 0.809);
    padding: 10px;
    padding-top: 20px;
}
/* ==== Right side of login ==== */
.lcontainer{
    grid-column: 2;
    width: 100%;
    height: 100vh;
    display: flex;

    justify-content: center;
    align-items: center;
}
.lbox1{
    width: 450px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logoc{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0px;
}
.loginlogo{
    border: 3px solid grey;
    border-radius: 50%;
}
.textbox h1{
    margin: 0;
    color: white;
    font-size: 3.0rem;
}

/* ===Demo=== */
.demo{
    grid-column: 3;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.demobox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.829);
    border-left: 3px solid grey;
    border-radius: 100px;
}
.demobox:hover{
    border: 3px solid white;
    
}
.demoheader{
    color: white;
    font-family: Karla;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 400px;
}
.demoheader h1{
    margin: 0;
    font-size: 2.5rem;
}
.demoheader p{
    margin: 30px;
    font-size: 1.5rem;
}
.demo button{
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transition: all .5s;
}
.demo button:hover{
    box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
    translate: 0px -5px;
    transition-duration: .1s;
}

/* 
.button-36 {
    background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    flex-shrink: 0;
    font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    padding: 0 1.6rem;
    text-align: center;
    text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
    transition: all .5s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-36:hover {
    box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
    transition-duration: .1s;
  }
  
  @media (min-width: 768px) {
    .button-36 {
      padding: 0 2.6rem;
    }
  }
*/