.main{
    background-color: rgb(20, 30, 37);
    width: 100%;
    height: 100vh;
}
.container{
    display: flex;
    width: 100%;
    height: 100vh;
}
.navcomp{
    display: flex;
}
.navbox{
    color: rgb(255, 255, 255);
    display: grid;
    justify-items: center;
    align-items: center;

    width: 300px;
    height: 100vh;
    background-color: rgb(30, 44, 56);
    

    font-family: Karla;
}
.navfooter{
    justify-self: end;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    
    text-align: center;
}
.feedbox{
    grid-column: span 2;
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    justify-content: center;
}
.feed{
    padding: 20px;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: rgb(30, 44, 56) rgb(20, 30, 37);   
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100vh;
    width: 750px;
    background-color: rgb(30, 44, 56);
    align-items: center;
}
.nothing{
    margin-top: 50px;
    color: white;
    font-family: Karla;
    border-radius: 70px;
    padding: 20px;
    text-align: center;
}
.nothing:hover{
    background-color: rgb(62, 103, 140);
    color: rgb(255, 206, 206);
    text-shadow: 1px 1px 2px rgb(0, 0, 0);
}
.arrow{
    font-size: 50px;
    color: rgb(255, 255, 255);
}

/* NAV AND BUTTONS */
.title{
    width: 100px;
    color: rgb(255, 90, 90);
    text-shadow: 1px 1px 2px rgb(253, 253, 253);
    font-family: Karla;
    font-size: 30px;
}


/* btcon */
/* container of the it all */
.rtwil{
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: 1fr;
    height: 100vh;
}

/* left side */
.btcon{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: start;
}
.selectbox{
    margin-top: 100px;
}
.sel{
    border: 3px solid rgb(255, 90, 90);
    border-radius: 30px;
    width: 200px;
    height: 50px;
    color: rgb(255, 248, 248);
    background-color:rgb(33, 48, 60);
}
.sel div{
    display: flex;
    justify-content: center;
    display: flex;
    background-color: rgb(30, 44, 56);
    border-radius: 25px;
    border: none;
}
.sel span{
    
    color: white;
    text-align: center;
    font-size: 17px;
    font-family: Karla;
    border: none;
}

